import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  unwrapResult,
} from '@reduxjs/toolkit';
import { redirect } from 'react-router-dom';
import QRCode from 'qrcode';
import { types as api } from '@mesa-labs/mesa-api';

import cognitoProvider, { CognitoBrowserService } from '../../cognito';
import * as types from './types';
import { vendorApi } from '../api/vendor';
import { updateOnboardingId } from './onboarding';
import { removeAnalyticsGroup, reset, setAnalyticsUserId } from '../../analytics';
import { RootState } from '../store';
import { UserAccountActivityType } from '@mesa-labs/mesa-api/dist/types';

export const ATTRIBUTE_MFA_METHOD = 'custom:mfaMethod';

export enum MfaMethod {
  GOOGLE_AUTH = 'Google Authenticator',
}

export enum AuthenticationGroup {
  OPERATORS = 'Operators',
  FACILITATORS = 'Facilitators',
  FINANCIERS = 'Financiers',
  VENDORS = 'Vendors',
}

export interface AuthState {
  externalVendorId?: string;
  vendorId?: string;
  email?: string;
  mfaCode: string;
  error?: Error;
  isLoggedIn: boolean;
  loading: boolean;
  qrCodeUrl?: string;
  mfaMethod?: MfaMethod;
  roles?: AuthenticationGroup[];
  isReadOnly: boolean;
}

type ChangePasswordArgs = {
  oldPassword: string;
  newPassword: string;
};

const initialState: AuthState = {
  externalVendorId: undefined,
  email: undefined,
  mfaCode: '',
  error: undefined,
  isLoggedIn: false,
  loading: false,
  vendorId: undefined,
  qrCodeUrl: undefined,
  roles: [],
  isReadOnly: true,
};

export const confirmSignUp = createAsyncThunk(
  'auth/confirmAccount',
  async (args: { email: string, code: string }): Promise<void> => {
    await cognitoProvider.confirmAccount(args.email, args.code);
  },
);

export const resendConfirmationCode = createAsyncThunk(
  'auth/resendConfirmationCode',
  async (args: { email: string }): Promise<void> => {
    await cognitoProvider.resendConfirmationCode(args.email);
  },
);

export const postSignIn = createAsyncThunk<{
  vendorId: string;
  mfaMethod: MfaMethod;
  isReadOnly: boolean;
  referralCode?: api.ProgramCodes,
}, {
  onboardingId?: string;
  initialSignIn?: boolean;
  email: string;
  promoCode?: api.PromoCodes;
  referralCode?: api.ProgramCodes;
}>(
  'auth/postSignIn',
  async (args: {
    onboardingId?: string,
    initialSignIn?: boolean,
    email: string,
    promoCode?: api.PromoCodes,
    referralCode?: api.ProgramCodes,
  }, thunkApi): Promise<{ vendorId: string; mfaMethod: MfaMethod; isReadOnly: boolean, referralCode?: api.ProgramCodes }> => {
    let attributes: Record<string, any> = {};

    const getUserAccountResult = await thunkApi.dispatch(
      vendorApi.endpoints.getUserAccount.initiate({ email: args.email }),
    );

    /** Initial user account creation */
    if (args.onboardingId) {
      const registerOnboardingResult = await thunkApi.dispatch(
        vendorApi.endpoints.registerOnboarding.initiate({ onboardingId: args.onboardingId }),
      );

      if ('error' in registerOnboardingResult) {
        await cognitoProvider.signOut();

        throw new Error('There was an error setting up your account. Please contact support@joinmesa.com for assistance.');
      }

      await cognitoProvider.refreshCurrentSession();

      await thunkApi.dispatch(updateOnboardingId(''));
    } else if (args.initialSignIn && !getUserAccountResult?.data?.parentId) {
      /** Create initial user account without association to an onboarding, but only if this is not a child account (no parentId set) */
      await thunkApi.dispatch(
        vendorApi.endpoints.createInitialUserAccount.initiate({ email: args.email, readOnly: false }),
      );
    }

    /** Add initial onboardingData on the userAccount if initial sign in and not a child account (no parentId set) */
    if (args.initialSignIn && !getUserAccountResult?.data?.parentId) {
      await thunkApi.dispatch(
        vendorApi.endpoints.addUserAccountOnboardingData.initiate({ 
          email: args.email, 
          onboardingData: { 
            promoCode: args.promoCode,
            referralCode: args.referralCode,
          },
        }),
      );
    }

    /** Child Account registration */
    let user = await cognitoProvider.getCurrentUser();
    attributes = user ? await cognitoProvider.getUserAttributes(user) : {};

    let vendorId = attributes['custom:vendorId'];
    const mfaMethod = attributes[ATTRIBUTE_MFA_METHOD] as MfaMethod;

    if (!vendorId && attributes.email) {
      // register child account if a parentId exists
      if (getUserAccountResult.data?.parentId) {
        const registerUserAccountResult = await thunkApi.dispatch(
          vendorApi.endpoints.registerUserAccount.initiate({ email: attributes.email }),
        );

        if ('error' in registerUserAccountResult) {
          await cognitoProvider.signOut();

          throw new Error('There was an error setting up your account. Please contact support@joinmesa.com for assistance.');
        }

        await cognitoProvider.refreshCurrentSession();
        user = await cognitoProvider.getCurrentUser();
        attributes = user ? await cognitoProvider.getUserAttributes(user) : {};

        vendorId = attributes['custom:vendorId'];
      }
    }

    return {
      vendorId,
      mfaMethod,
      isReadOnly: attributes['custom:readOnly'] === 'true',
      referralCode: args.referralCode || getUserAccountResult.data?.onboardingData?.referralCode,
    };
  },
);

export const signIn = createAsyncThunk<{
  vendorId: string;
  mfaMethod: MfaMethod;
  isReadOnly: boolean;
}, {
  email: string;
  password: string;
  onboardingId?: string;
  totpCallback: any;
  initialSignIn?: boolean;
  promoCode?: api.PromoCodes;
  referralCode?: api.ProgramCodes;
}>(
  'auth/signIn',
  async (args: {
    email: string,
    password: string,
    onboardingId?: string | undefined,
    totpCallback: () => Promise<string>,
    initialSignIn?: boolean,
    promoCode?: api.PromoCodes,
    referralCode?: api.ProgramCodes,
  }, thunkApi): Promise<{ vendorId: string; mfaMethod: MfaMethod; isReadOnly: boolean }> => {
    const {
      email,
      password,
      onboardingId,
      totpCallback,
      initialSignIn,
      promoCode,
      referralCode, 
    } = args;

    // clear any prior session first
    await cognitoProvider.signOut();
    await cognitoProvider.signIn(email, password, totpCallback);

    cognitoProvider.subscribe((event) => {
      if (event === 'refresh_session') {
        thunkApi.dispatch(vendorApi.endpoints.logUserAccountActivity.initiate({ email, activity: UserAccountActivityType.AuthRefresh })).catch(() => { /* */ });
      }
    });

    const session = await cognitoProvider.getCurrentSession();
    const sessionPayload = session?.getIdToken().decodePayload() || {};
    const cognitoUsername = sessionPayload['cognito:username'];

    // set GA userId config property to the cognitoUsername
    if (cognitoUsername) {
      setAnalyticsUserId(cognitoUsername);
    }

    thunkApi.dispatch(vendorApi.endpoints.logUserAccountActivity.initiate({ email, activity: UserAccountActivityType.AuthSignIn })).catch(() => { /* */ });

    return unwrapResult(await thunkApi.dispatch(postSignIn({ onboardingId, initialSignIn, email, promoCode, referralCode })));
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (args: { 
    email: string, 
    password: string, 
    trackingPartnerId: string, 
    onboardingId?: string,
    promoCode?: api.PromoCodes,
    referralCode?: api.ProgramCodes,
  }, { dispatch }): Promise<void> => {
    // clear any prior session first
    await cognitoProvider.signOut();

    const onboardingPartnerIdAttribute = CognitoBrowserService.createCognitoUserAttribute('custom:trackingPartnerId', args.trackingPartnerId);

    // On signUp, attempt to auto signIn if the 'confirm_account' event is dispatched
    // by our cognitoProvider. Depending on if the vendor is created (vendorId) is set,
    // the user is either redirected to the onboarding flow (no vendorId) or to the dashboard
    // (vendorId, therefore a child account).
    cognitoProvider.subscribe(async (evt) => {
      if (evt === 'confirm_account') {
        const result = await dispatch(signIn({
          email: args.email,
          password: args.password,
          totpCallback: () => Promise.resolve(''),
          initialSignIn: true,
          onboardingId: args.onboardingId,
          promoCode: args.promoCode,
          referralCode: args.referralCode,
        })) as ({
          payload: {
            vendorId: string,
          },
        });

        if (result?.payload?.vendorId) { // if the vendor exists, redirect to / (dashboard route)
          redirect('/');
        } else { // otherwise, if vendor does not exist, redirect to onboarding
          redirect('/onboarding/business-info');
        }
      }
    });

    await cognitoProvider.signUp(
      args.email,
      args.password,
      undefined,
      [onboardingPartnerIdAttribute],
    );
  },
);

export const signOut = createAsyncThunk<
  void,
  void,
  { state: RootState }
>(
  'auth/signOut',
  async (_, { dispatch, getState }): Promise<void> => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem('persist:vendor');

    const { promoCode, partnerId } = getState().onboarding;

    if (promoCode) {
      removeAnalyticsGroup('promoCode', promoCode as string);
    }
    if (partnerId) {
      removeAnalyticsGroup('partner', partnerId as number);
    }

    reset();

    const email = getState().auth.email;
    if (email) {
      await dispatch(vendorApi.endpoints.logUserAccountActivity.initiate({ email, activity: UserAccountActivityType.AuthSignOut })).catch(() => { /* */ });
    }

    await cognitoProvider.signOut();
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (args: ChangePasswordArgs): Promise<void> => {
    await cognitoProvider.changePassword(args.oldPassword, args.newPassword);
  },
);

export const getMfaQrCodeUrl = createAsyncThunk(
  'auth/getMfaQrCodeUrl',
  async (args: { email: string }): Promise<{ qrCodeUrl: string }> => {
    const response = await cognitoProvider.associateSoftwareToken();
    const otpUrl = `otpauth://totp/${args.email}?secret=${response}&issuer=Mesa`;
    const qrCodeUrl = await new Promise<string>((resolve) => QRCode.toDataURL(otpUrl, (err: any, data_url: string) => {
      resolve(data_url);
    }));
    return { qrCodeUrl };
  },
);

export const updateMfaPreference = createAsyncThunk(
  'auth/updateMfaPreference',
  async (args: { code: string, mfaMethod: string }): Promise<{ mfaMethod: MfaMethod }> => {
    await cognitoProvider.verifySoftwareToken(args.code, 'SOFTWARE_TOKEN_MFA');
    await cognitoProvider.setUserMFAPreference();
    const user = await cognitoProvider.getCurrentUser();
    if (user) {
      await cognitoProvider.updateUserAttributes(user, [{ Name: ATTRIBUTE_MFA_METHOD, Value: args.mfaMethod }]);
    }
    return { mfaMethod: args.mfaMethod as MfaMethod };
  },
);

export const removeMfa = createAsyncThunk(
  'auth/removeMfa',
  async (): Promise<void> => {
    await cognitoProvider.removeUserMFAPreference();
    const user = await cognitoProvider.getCurrentUser();
    if (user) {
      await cognitoProvider.deleteUserAttributes(user, [ATTRIBUTE_MFA_METHOD]);
    }
  },
);

export const setupCognito = createAsyncThunk(
  'auth/setupCognito',
  async (): Promise<{
    externalVendorId?: string,
    email?: string,
    vendorId?: string,
    mfaMethod?: MfaMethod,
    roles: AuthenticationGroup[];
    isReadOnly: boolean;
  }> => {
    let externalVendorId: string | undefined;
    let email: string | undefined;
    let vendorId: string | undefined;
    let mfaMethod: MfaMethod | undefined;
    let roles: AuthenticationGroup[] = [];
    let isReadOnly = true;

    const currentSession = await cognitoProvider.getCurrentSession();
    const session = currentSession && !currentSession.isValid()
      ? await cognitoProvider.refreshCurrentSession()
      : currentSession;

    if (session?.isValid()) {
      const user = await cognitoProvider.getCurrentUser();
      const attributes = user ? await cognitoProvider.getUserAttributes(user) : {};
      const jwtTokenPayload = user?.getSignInUserSession()?.getIdToken().payload || {};

      mfaMethod = attributes['custom:mfaMethod'] as MfaMethod;
      roles = jwtTokenPayload['cognito:groups'] || [];
      if (attributes.email_verified) {
        externalVendorId = attributes['custom:externalVendorId'] || attributes['custom:e1VendorNumber'];
        email = attributes.email;
        vendorId = attributes['custom:vendorId'];
        isReadOnly = attributes['custom:readOnly'] === 'true';
      }
    }

    return {
      externalVendorId,
      email,
      vendorId,
      mfaMethod,
      roles,
      isReadOnly,
    };
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (args: { email: string }): Promise<void> => {
    await cognitoProvider.forgotPassword(args.email);
  },
);

export const forgotPasswordSubmit = createAsyncThunk(
  'auth/forgotPasswordSubmit',
  async (args: { email: string, code: string, password: string }): Promise<void> => {
    await cognitoProvider.forgotPasswordSubmit(args.email, args.code, args.password);
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateEmail(state: AuthState, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    updateMfaCode(state: AuthState, action: PayloadAction<string>) {
      state.mfaCode = action.payload;
    },
    updateIsLoggedIn(state: AuthState, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    updateExternalVendorId(state: AuthState, action: PayloadAction<string>) {
      state.externalVendorId = action.payload;
    },
    clearError(state: AuthState) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupCognito.fulfilled, (state: AuthState, action) => {
        state.email = action.payload.email;
        state.externalVendorId = action.payload.externalVendorId ?? state.externalVendorId;
        state.vendorId = action.payload.vendorId;
        state.isLoggedIn = !!action.payload.email;
        state.mfaMethod = action.payload.mfaMethod;
        state.roles = action.payload.roles;
        state.isReadOnly = action.payload.isReadOnly;
      })
      .addCase(setupCognito.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(signIn.fulfilled, (state: AuthState) => {
        state.isLoggedIn = true;
      })
      .addCase(signIn.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(signUp.fulfilled, (state: AuthState, action) => {
        state.email = action.meta.arg.email;
      })
      .addCase(signOut.fulfilled, (state: AuthState) => {
        state.email = '';
        state.isLoggedIn = false;
      })
      .addCase(postSignIn.fulfilled, (state: AuthState, action) => {
        state.isLoggedIn = true;
        state.vendorId = action.payload.vendorId;
        state.mfaMethod = action.payload.mfaMethod;
        state.isReadOnly = action.payload.isReadOnly;
      })
      .addCase(updateMfaPreference.fulfilled, (state: AuthState, action) => {
        state.mfaMethod = action.payload.mfaMethod;
      })
      .addCase(removeMfa.fulfilled, (state: AuthState) => {
        state.mfaMethod = undefined;
      })
      .addCase(getMfaQrCodeUrl.fulfilled, (state: AuthState, action) => {
        state.qrCodeUrl = action.payload.qrCodeUrl;
      })
      .addMatcher((action) => types.isPendingAction(action, 'auth'), (state: AuthState) => {
        state.loading = true;
      })
      .addMatcher((action) => types.isRejectedAction(action, 'auth'), (state: AuthState, action: any) => {
        state.loading = false;

        if (!action.payload) {
          state.error = action.error;
        }
      })
      .addMatcher((action) => types.isFulfilledAction(action, 'auth'), (state: AuthState) => {
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const {
  clearError,
  updateEmail,
  updateMfaCode,
  updateIsLoggedIn,
  updateExternalVendorId,
} = authSlice.actions;

export default authSlice.reducer;
